const config = {
    // basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
    // like '/berry-material-react/react/default'
    basename: '/',
    defaultPath: '/',
    fontFamily: `'Roboto', sans-serif`,
    borderRadius: 4,

    //****************** Change Title Also *****************************/
    //serverURL: 'https://suryaapi.agsapplications.com' //Production New
    //****************** Change Title Also *****************************/

    //serverURL: 'https://localhost:7183'
    serverURL: 'https://suryaapitest.agsapplications.com' //Test Server
    //This is trial for commit
};

export default config;
