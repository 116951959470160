import { useState, useEffect } from 'react';

//Mui imports
import {
    Button,
    Grid,
    Typography,
    Card,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Select,
    Stack,
    TextField,
    MenuItem,
    FormControl,
    InputLabel,
    Divider,
    FormHelperText,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    AccordionActions,
    CircularProgress
} from '@mui/material';
import { Box } from '@mui/system';
import { useTheme } from '@mui/material/styles';
import BagType from 'ui-component/BagType';

//--------------------------------Waste Collection Rerpot Table------------------------------------//

const ReportTableTotal = (props) => {
    const { reportData } = props;
    const theme = useTheme();

    return (
        <>
            {reportData != undefined || reportData != null ? (
                <Card variant="outlined" sx={{ marginTop: 2 }}>
                    <TableContainer>
                        <Table size="small">
                            <TableHead sx={{ backgroundColor: theme.palette.primary.light }}>
                                <TableRow>
                                    <TableCell sx={{ width: 60 }}>Sl.</TableCell>
                                    <TableCell sx={{ width: 80 }} align="left">
                                        Bag Type
                                    </TableCell>
                                    <TableCell sx={{ width: 150 }} align="left">
                                        Bag Count
                                    </TableCell>
                                    <TableCell sx={{ width: 150 }} align="left">
                                        Weight (Kg.)
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        <Typography variant="body2">1</Typography>
                                    </TableCell>
                                    <TableCell align="left">
                                        <Stack direction="row" alignItems="center" spacing={2}>
                                            <BagType bagType={0} />
                                            <Typography variant="body2">Yellow</Typography>
                                        </Stack>
                                    </TableCell>
                                    <TableCell align="left">
                                        <Typography variant="body2">{reportData.totalYellowBags}</Typography>
                                    </TableCell>
                                    <TableCell align="left">
                                        <Typography variant="body2">{reportData.totalWeightofYellowBags}</Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        <Typography variant="body2">2</Typography>
                                    </TableCell>
                                    <TableCell align="left">
                                        <Stack direction="row" alignItems="center" spacing={2}>
                                            <BagType bagType={1} />
                                            <Typography variant="body2">Red</Typography>
                                        </Stack>
                                    </TableCell>
                                    <TableCell align="left">
                                        <Typography variant="body2">{reportData.totalRedBags}</Typography>
                                    </TableCell>
                                    <TableCell align="left">
                                        <Typography variant="body2">{reportData.totalWeightofRedBags}</Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        <Typography variant="body2">3</Typography>
                                    </TableCell>
                                    <TableCell align="left">
                                        <Stack direction="row" alignItems="center" spacing={2}>
                                            <BagType bagType={2} />
                                            <Typography variant="body2">White</Typography>
                                        </Stack>
                                    </TableCell>
                                    <TableCell align="left">
                                        <Typography variant="body2">{reportData.totalWhiteBags}</Typography>
                                    </TableCell>
                                    <TableCell align="left">
                                        <Typography variant="body2">{reportData.totalWeightofWhiteBags}</Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        <Typography variant="body2">4</Typography>
                                    </TableCell>
                                    <TableCell align="left">
                                        <Stack direction="row" alignItems="center" spacing={2}>
                                            <BagType bagType={3} />
                                            <Typography variant="body2">Blue</Typography>
                                        </Stack>
                                    </TableCell>
                                    <TableCell align="left">
                                        <Typography variant="body2">{reportData.totalBlueBags}</Typography>
                                    </TableCell>
                                    <TableCell align="left">
                                        <Typography variant="body2">{reportData.totalWeightofBlueBags}</Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        <Typography variant="body2">5</Typography>
                                    </TableCell>
                                    <TableCell align="left">
                                        <Stack direction="row" alignItems="center" spacing={2}>
                                            <BagType bagType={4} />
                                            <Typography variant="body2">Yellow - C</Typography>
                                        </Stack>
                                    </TableCell>
                                    <TableCell align="left">
                                        <Typography variant="body2">{reportData.totalYellowCBags}</Typography>
                                    </TableCell>
                                    <TableCell align="left">
                                        <Typography variant="body2">{reportData.totalWeightofYellowCBags}</Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        <Typography variant="body2">5</Typography>
                                    </TableCell>
                                    <TableCell align="left">
                                        <Stack direction="row" alignItems="center" spacing={2}>
                                            <Typography variant="h5">Total</Typography>
                                        </Stack>
                                    </TableCell>
                                    <TableCell align="left">
                                        <Typography variant="h5">{reportData.totalBags}</Typography>
                                    </TableCell>
                                    <TableCell align="left">
                                        <Typography variant="h5">{reportData.totalWeight}</Typography>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Card>
            ) : null}
        </>
    );
};

export default ReportTableTotal;
