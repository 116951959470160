// assets
import {
    IconDashboard,
    IconBrandChrome,
    IconHelp,
    IconUsers,
    IconUser,
    IconRoute,
    IconLayoutKanban,
    IconReport,
    IconSettings
} from '@tabler/icons';

// constant
const icons = { IconDashboard, IconBrandChrome, IconHelp, IconUsers, IconUser, IconRoute, IconLayoutKanban, IconReport, IconSettings };

// ==============================|| CUSTOMER MENU ITEMS ||============================== //

const customerMenuItems = [
    // {
    //     id: 'dashboard',
    //     type: 'group',
    //     children: [
    //         {
    //             id: 'default',
    //             title: 'Dashboard',
    //             type: 'item',
    //             url: '/dashboard',
    //             icon: icons.IconDashboard,
    //             breadcrumbs: false
    //         }
    //     ]
    // },
    {
        id: 'main-pages',
        type: 'group',
        children: [
            {
                id: 'profile',
                title: 'Profile',
                type: 'item',
                url: '/customer/customer-profile',
                icon: icons.IconUser,
                breadcrumbs: false
            },
            {
                id: 'reports',
                title: 'Reports',
                type: 'item',
                url: '/customer/collection-reports',
                icon: icons.IconReport,
                breadcrumbs: false
            }
            // {
            //     id: 'settings',
            //     title: 'Settings',
            //     type: 'item',
            //     url: '/sample-page',
            //     icon: icons.IconSettings,
            //     breadcrumbs: false
            // }
        ]
    }
];

export default customerMenuItems;
