import React from 'react';
import { useTheme, Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const AlertMessage = ({ message, opendeleteTost, handleCloseDeleteToast, severity }) => {
    const theme = useTheme();
    return (
        <>
            <Snackbar open={opendeleteTost} autoHideDuration={6000} onClose={handleCloseDeleteToast}>
                <Alert onClose={handleCloseDeleteToast} severity={severity == 1 ? 'error' : 'success'} sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
        </>
    );
};

export default AlertMessage;
