import { useState, useEffect } from 'react';

//material ui imports
import {
    Grid,
    TextField,
    Typography,
    Divider,
    Button,
    Stack,
    Card,
    FormControl,
    FormLabel,
    InputLabel,
    Select,
    MenuItem,
    FormHelperText
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PersonIcon from '@mui/icons-material/Person';

//project imports
import config from 'config';

//third party imports
import * as Yup from 'yup';
import { useFormik } from 'formik';
import axios from 'axios';
import AlertMessage from 'ui-component/AlertMessage';
import { useSelector } from 'react-redux';
const roles = [
    // { roleId: 1, rolename: 'Admin' },
    // { roleId: 2, rolename: 'Customer' },
    { roleId: 3, rolename: 'Recovery Manager' },
    { roleId: 4, rolename: 'Recovery Executive' },
    { roleId: 5, rolename: 'Route Manager' },
    { roleId: 8, rolename: 'Route Executive' },
    { roleId: 6, rolename: 'Accountant' },
    { roleId: 9, rolename: 'Guest' }
    // { roleId: 8, rolename: 'Driver' },
    // { roleId: 9, rolename: 'Customer Staff' }
    // { roleId: 10, rolename: 'Guest' }
];
const EmployeeForm = (props) => {
    const theme = useTheme();
    const { userId } = useSelector((state) => state.userReducer);
    const [errorMessage, setErrorMessage] = useState('');
    const { employee, onCancel, onSave } = props;
    const [submitting, setSubmitting] = useState(false);
    const [user, setUser] = useState({
        roleId: 3,
        rolename: 'Recovery Manager',
        displayName: '',
        username: '',
        emailAddress: '',
        mobileNumber: '',
        password: '',
        id: null,
        createdBy: 0,
        updatedBy: 0,
        deletedBy: 0
    });
    const [opendeleteTost, setOpenDeleteTost] = useState(false);

    useEffect(() => {
        if (employee) {
            axios
                .get(config.serverURL + `/api/Users/${employee}`)
                .then((response) => {
                    response.data.roleId = response.data.roleMasterId;
                    delete response.data.roleMasterId;
                    setUser(response.data);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, [employee]);
    const formik = useFormik({
        initialValues: user,
        enableReinitialize: true,
        validationSchema: Yup.object({
            roleId: Yup.number().required('Select Role'),
            displayName: Yup.string().max(50, 'Name must be 50 characters or less').required('Name is required'),
            emailAddress: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
            mobileNumber: Yup.string()
                .matches(
                    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
                    'Phone number is not valid'
                )
                .min(10, 'Minimum 10 digits are required')
                .max(10, 'Max 10 digits are allowed')
                .required('Enter mobile number'),
            password: Yup.string().required('enter password')
        }),
        onSubmit: (values) => {
            setSubmitting(true);
            values.username = values.emailAddress;
            if (values.id === null) {
                values.createdBy = userId;
                axios
                    .post(config.serverURL + '/api/Users', values)
                    .then((response) => {
                        console.log(response.data);
                        if (response.status === 200 && response.data != 0) {
                            setSubmitting(false);
                            onSave();
                            setOpenDeleteTost(true);
                        } else {
                            setErrorMessage('Something Wents Wrong');
                        }
                    })
                    .catch((err) => {});
            } else {
                values.updatedBy = userId;
                axios
                    .put(config.serverURL + '/api/Users?id=' + values.id, values)
                    .then((response) => {
                        console.log(response.data);
                        if (response.status === 200) {
                            setSubmitting(false);
                            onSave();
                            setOpenDeleteTost(true);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        }
    });
    useEffect(() => {
        setTimeout(() => {
            setOpenDeleteTost(false);
        }, [1000]);
    }, [opendeleteTost]);
    console.log('formik', formik.errors, formik.values);

    return (
        <>
            <Grid container>
                {employee.id === null ? (
                    <Stack direction="row" sx={{ marginX: 'auto' }}>
                        <PersonIcon />
                        <Typography
                            color={theme.palette.primary.dark}
                            variant="h4"
                            textAlign="center"
                            sx={{ marginY: 'auto', marginLeft: 2 }}
                        >
                            Add New User
                        </Typography>
                    </Stack>
                ) : (
                    <Stack direction="row" sx={{ marginX: 'auto' }}>
                        <PersonIcon />

                        <Typography
                            color={theme.palette.primary.dark}
                            variant="h4"
                            textAlign="center"
                            sx={{ marginY: 'auto', marginLeft: 2 }}
                        >
                            Edit User Details
                        </Typography>
                    </Stack>
                )}
            </Grid>
            <Divider sx={{ marginY: 3 }} />
            <Grid>
                <form onSubmit={formik.handleSubmit}>
                    <FormControl fullWidth sx={{ marginBottom: 3 }}>
                        <InputLabel id="demo-simple-select-helper-label">Role</InputLabel>
                        <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            value={formik.values.roleId}
                            label="Role"
                            name="roleId"
                            onChange={formik.handleChange}
                            // error={formik.touched.roleId && Boolean(formik.values.roleId)}
                            // helperText={formik.touched.roleId && formik.errors.roleId}
                        >
                            {roles.map((role, i) => (
                                <MenuItem value={role.roleId} key={i}>
                                    {role.rolename}
                                </MenuItem>
                            ))}
                        </Select>
                        {formik.errors.roleId && <FormHelperText error>{formik.errors.roleId}</FormHelperText>}
                    </FormControl>
                    <TextField
                        name="displayName"
                        label="Name"
                        onChange={formik.handleChange}
                        value={formik.values.displayName}
                        fullWidth
                        error={formik.touched.displayName && Boolean(formik.errors.displayName)}
                        helperText={formik.touched.displayName && formik.errors.displayName}
                        sx={{ marginBottom: 3, color: theme.palette.error.dark }}
                    />

                    <TextField
                        name="mobileNumber"
                        label="Mobile No."
                        onChange={formik.handleChange}
                        value={formik.values.mobileNumber}
                        fullWidth
                        error={formik.touched.mobileNumber && Boolean(formik.errors.mobileNumber)}
                        helperText={formik.touched.mobileNumber && formik.errors.mobileNumber}
                        sx={{ marginBottom: 3 }}
                    />
                    {/* <TextField
                        name="username"
                        label="User Name"
                        onChange={formik.handleChange}
                        value={formik.values.username}
                        fullWidth
                        error={formik.touched.username && Boolean(formik.errors.username)}
                        helperText={formik.touched.username && formik.errors.username}
                        sx={{ marginBottom: 3 }}
                    /> */}
                    <TextField
                        name="emailAddress"
                        label="Email Address"
                        onChange={formik.handleChange}
                        value={formik.values.emailAddress}
                        fullWidth
                        error={formik.touched.emailAddress && Boolean(formik.errors.emailAddress)}
                        helperText={formik.touched.emailAddress && formik.errors.emailAddress}
                        sx={{ marginBottom: 3 }}
                    />
                    <TextField
                        name="password"
                        label="password"
                        onChange={formik.handleChange}
                        value={formik.values.password}
                        fullWidth
                        error={formik.touched.password && formik.errors.password}
                        helperText={formik.touched.password && formik.errors.password}
                        sx={{ marginBottom: 3 }}
                    />
                    <FormHelperText error>{errorMessage}</FormHelperText>
                    <Divider sx={{ marginY: 2 }} />
                    <Button variant="contained" type="submit" disabled={submitting}>
                        Save
                    </Button>
                    <Button variant="outlined" onClick={onCancel} sx={{ marginLeft: 2 }}>
                        Cancel
                    </Button>
                </form>
            </Grid>
            <AlertMessage message={'Record saved succesfully!!'} opendeleteTost={opendeleteTost} />
        </>
    );
};

export default EmployeeForm;
