import React from 'react';

const MachineDetails = () => {
    return <div>MachineDetails</div>;
    // Display machine form
    // Give button to add new maintenance schedule
    // Display list of maintenance schedules for this machine in table formate and give buttons to edit and delete. Edit button should open dialog for Maintenance Form.
};

export default MachineDetails;
