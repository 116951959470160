import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, ButtonBase, Stack, Typography } from '@mui/material';

// project imports
import LogoSection from '../LogoSection';
import SearchSection from './SearchSection';
import ProfileSection from './ProfileSection';
import NotificationSection from './NotificationSection';
import packageJson from '../../../../package.json';

// assets
import { IconMenu2 } from '@tabler/icons';
import config from 'config';
import axios from 'axios';
import { useEffect } from 'react';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({ handleLeftDrawerToggle }) => {
    const theme = useTheme();
    const { profileId, role, displayName } = useSelector((state) => state.userReducer);
    let version = localStorage.getItem('version');

    const timeOut = 3 * 60 * 1000;

    const caching = () => {
        axios.get(config.serverURL + '/api/Auth/GetApplicationVersion/v1/application-version').then((response) => {
            let version = localStorage.getItem('version');
            console.log(version);
            if (version == null || version == undefined) {
                localStorage.setItem('version', response.data.webAppVersion);
            }
            if (version != response.data.webAppVersion) {
                if ('caches' in window) {
                    caches.keys().then((names) => {
                        // Delete all the cache files
                        names.forEach((name) => {
                            caches.delete(name);
                        });
                    });

                    // Makes sure the page reloads. Changes are only visible after you refresh.
                    window.location.reload(true);
                }

                localStorage.clear();
                localStorage.setItem('version', response.data.webAppVersion);
            }
        });
    };

    useEffect(() => {
        caching();
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            caching();
        }, timeOut);

        return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    }, []);

    return (
        <>
            {/* logo & toggler button */}
            <Box
                sx={{
                    width: 210,
                    display: 'flex',
                    [theme.breakpoints.down('md')]: {
                        width: 'auto'
                    }
                }}
            >
                <Box component="span" sx={{ display: { xs: 'none', md: 'flex' }, flexGrow: 1, alignItems: 'center' }}>
                    <LogoSection />
                </Box>
                <ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden', margin: 'auto' }}>
                    <Avatar
                        variant="rounded"
                        sx={{
                            ...theme.typography.commonAvatar,
                            ...theme.typography.mediumAvatar,
                            transition: 'all .2s ease-in-out',
                            background: theme.palette.secondary.light,
                            color: theme.palette.secondary.dark,
                            '&:hover': {
                                background: theme.palette.secondary.dark,
                                color: theme.palette.secondary.light
                            }
                        }}
                        onClick={handleLeftDrawerToggle}
                        color="inherit"
                    >
                        <IconMenu2 stroke={1.5} size="1.3rem" />
                    </Avatar>
                </ButtonBase>
            </Box>

            {/* header search
            <SearchSection />*/}
            <Box sx={{ flexGrow: 1 }} />
            <Stack alignItems="center" sx={{ marginLeft: 2 }}>
                <Typography variant="h3" color="secondary">
                    SCTF Portal (Test)
                </Typography>
                <Typography variant="caption">Version {version}</Typography>
            </Stack>
            <Box sx={{ flexGrow: 1 }} />
            <Stack direction="row" spacing={2} alignItems="center">
                <Stack>
                    <Typography variant="h5">Hello, {displayName}</Typography>
                    <Typography variant="caption">{role}</Typography>
                </Stack>
                <ProfileSection />
            </Stack>
        </>
    );
};

Header.propTypes = {
    handleLeftDrawerToggle: PropTypes.func
};

export default Header;
