import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';

// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';
import packageJson from '../package.json';

// ==============================|| APP ||============================== //

const App = () => {
    const customization = useSelector((state) => state.customization);

    // const caching = () => {
    //     let version = localStorage.getItem('version');
    //     console.log(version);
    //     if (version == null) {
    //         localStorage.setItem('version', packageJson.version);
    //     }
    //     if (version != packageJson.version) {
    //         if ('caches' in window) {
    //             caches.keys().then((names) => {
    //                 // Delete all the cache files
    //                 names.forEach((name) => {
    //                     caches.delete(name);
    //                 });
    //             });

    //             // Makes sure the page reloads. Changes are only visible after you refresh.
    //             window.location.reload(true);
    //         }

    //         localStorage.clear();
    //         localStorage.setItem('version', packageJson.version);
    //     }
    // };

    // useEffect(() => {
    //     caching();
    // }, []);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes(customization)}>
                <CssBaseline />
                <NavigationScroll>
                    <Routes />
                </NavigationScroll>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
