import { useState, useEffect } from 'react';

//Mui imports
import {
    Button,
    Grid,
    Typography,
    Card,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Select,
    Stack,
    TextField,
    MenuItem,
    FormControl,
    InputLabel,
    Divider,
    FormHelperText,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    AccordionActions,
    CircularProgress,
    Link
} from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';

import { Box } from '@mui/system';
import { useTheme } from '@mui/material/styles';
import BagType from 'ui-component/BagType';

//--------------------------------Waste Collection Rerpot Table------------------------------------//

const ReportTableList = (props) => {
    const { reportData } = props;
    const theme = useTheme();

    return (
        <>
            {reportData != undefined || reportData != null ? (
                <Card variant="outlined" sx={{ marginTop: 2 }}>
                    <Typography variant="h5" textAlign="center" sx={{ marginY: 2 }}>
                        (Total Count: {reportData.length})
                    </Typography>
                    <Box>
                        <TableContainer>
                            <Table size="small" stickyHeader={true}>
                                <TableHead sx={{ backgroundColor: theme.palette.primary.light }}>
                                    <TableRow>
                                        <TableCell sx={{ width: 30, backgroundColor: theme.palette.primary.light }}>Sl.</TableCell>
                                        <TableCell sx={{ width: 150, backgroundColor: theme.palette.primary.light }} align="left">
                                            HCF Details
                                        </TableCell>
                                        <TableCell sx={{ width: 100, backgroundColor: theme.palette.primary.light }} align="left">
                                            Date Time
                                        </TableCell>
                                        <TableCell sx={{ width: 120, backgroundColor: theme.palette.primary.light }} align="left">
                                            Bag Type
                                        </TableCell>
                                        <TableCell sx={{ backgroundColor: theme.palette.primary.light }} align="left">
                                            Barcode
                                        </TableCell>
                                        <TableCell sx={{ width: 120, backgroundColor: theme.palette.primary.light }} align="left">
                                            Bag Wt. (Kg)
                                        </TableCell>
                                        <TableCell sx={{ width: 150, backgroundColor: theme.palette.primary.light }} align="left">
                                            Collected By
                                        </TableCell>
                                        <TableCell sx={{ width: 150, backgroundColor: theme.palette.primary.light }} align="left">
                                            Collection Type
                                        </TableCell>
                                        <TableCell sx={{ width: 80, backgroundColor: theme.palette.primary.light }} align="left">
                                            Location
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {reportData.map((row, index) => (
                                        <TableRow key={index}>
                                            <TableCell component="th" scope="row">
                                                <Typography variant="body2">{index + 1}</Typography>
                                            </TableCell>
                                            <TableCell align="left">
                                                <Typography variant="body2">{row.hospitalName}</Typography>
                                                <Typography variant="caption">{row.uin}</Typography>
                                            </TableCell>
                                            <TableCell align="left">
                                                <Typography variant="body2">{row.dateTime}</Typography>
                                            </TableCell>
                                            <TableCell align="left">
                                                <Typography variant="body2">{row.bagType}</Typography>
                                            </TableCell>
                                            <TableCell align="left">
                                                <Typography variant="body2">{row.barcode}</Typography>
                                            </TableCell>
                                            <TableCell align="left">
                                                <Typography variant="body2">{row.weight}</Typography>
                                            </TableCell>
                                            <TableCell align="left">
                                                <Typography variant="body2">{row.driverName}</Typography>
                                                <Typography variant="caption">{row.vehicleNumber}</Typography>
                                            </TableCell>
                                            <TableCell align="left">
                                                <Typography variant="body2">
                                                    <Typography variant="body2">{row.collectionType}</Typography>
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="center">
                                                <Link
                                                    href={`https://www.google.com/maps/search/?api=1&query=${row.latitude},${row.longitude}`}
                                                    target="_blank"
                                                    sx={{ alignContent: 'center', display: 'flex' }}
                                                >
                                                    <LocationOnIcon fontSize="large" />
                                                </Link>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Card>
            ) : null}
        </>
    );
};

export default ReportTableList;
