import { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

//Mui imports
import {
    Button,
    Grid,
    Typography,
    Card,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Select,
    Stack,
    TextField,
    MenuItem,
    FormControl,
    InputLabel,
    Divider,
    FormHelperText,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    AccordionActions,
    CircularProgress,
    Autocomplete
} from '@mui/material';
import { Box } from '@mui/system';
import { useTheme } from '@mui/material/styles';

//Icons import
import LocalPrintshopRoundedIcon from '@mui/icons-material/LocalPrintshopRounded';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FilterListIcon from '@mui/icons-material/FilterList';

//Project imports

//Third Party Imports
import config from 'config';
import axios from 'axios';
import { useReactToPrint } from 'react-to-print';
import IndividualWasteCollectionReportPrint from 'views/reports/individual-waste-collection-report/IndividualWasteCollectionReportPrint';
import MainCard from 'ui-component/cards/MainCard';
import CustomerProfileCollectionReportPrint from './CustomerProfileCollectionReportPrint';
import ReportTableList from './ReportTableList';
import ReportTableTotal from './ReportTableTotal';

const CustomerProfileCollectionReport = () => {
    const theme = useTheme();
    const { userId, profileId, displayName, role } = useSelector((state) => state.userReducer);

    const componentRef = useRef();
    const [printCmd, setPrintCmd] = useState();

    const [fromDate, setFromDate] = useState(new Date().toISOString().split('T')[0]);
    const [toDate, setToDate] = useState(new Date().toISOString().split('T')[0]);
    const [loading, setLoading] = useState(false);
    const [customer, setCustomer] = useState();
    const [reportData, setReportData] = useState();

    const onPrintComplete = () => {
        setPrintCmd(false);
    };

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        onAfterPrint: onPrintComplete
    });

    useEffect(() => {
        if (printCmd) {
            handlePrint();
        }
    });

    const getData = () => {
        setLoading(true);
        axios
            .post(
                config.serverURL + `/api/Reports/waste-collection-by-customer?customerId=${profileId}&fromDate=${fromDate}&toDate=${toDate}`
            )
            .then((response) => {
                console.log(response.data);
                setReportData(response.data);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    };

    const getCustomer = () => {
        axios.get(config.serverURL + '/api/Customers/getById/' + profileId).then((response) => {
            if (response.status === 200) {
                console.log(response.data);
                setCustomer(response.data);
            }
        });
    };

    useEffect(() => {
        getCustomer();
        getData();
    }, []);

    return (
        <MainCard title="Waste Collection Report">
            <Stack direction="row" spacing={2} sx={{ marginTop: 2 }}>
                <TextField
                    name="fromDate"
                    label="From"
                    type="date"
                    onChange={(e) => {
                        setFromDate(e.target.value);
                    }}
                    value={fromDate}
                    sx={{ width: 300 }}
                />
                <TextField
                    name="toDate"
                    label="To"
                    type="date"
                    onChange={(e) => {
                        setToDate(e.target.value);
                    }}
                    value={toDate}
                    sx={{ width: 300 }}
                />
            </Stack>
            <Stack direction="row" spacing={2} sx={{ marginTop: 2 }}>
                <Button
                    variant="outlined"
                    onClick={() => {
                        setReportData(undefined);
                        getData();
                    }}
                    disabled={loading}
                >
                    View Report
                </Button>
                <Button
                    variant="outlined"
                    onClick={() => {
                        // getData();
                        setPrintCmd(true);
                    }}
                    disabled={loading || reportData === undefined}
                >
                    <LocalPrintshopRoundedIcon />
                </Button>
                {customer != undefined && (
                    <div style={{ display: 'none' }}>
                        <CustomerProfileCollectionReportPrint
                            ref={componentRef}
                            tableTotalData={reportData?.collectionSummary}
                            tableListData={reportData?.collectionDetails}
                            customerDetails={customer}
                            fromDate={fromDate}
                            toDate={toDate}
                        />
                    </div>
                )}
            </Stack>
            <>
                {reportData != undefined ? (
                    <>
                        <Typography variant="h4" color="primary" sx={{ mt: 2 }}>
                            Summary
                        </Typography>
                        <ReportTableTotal reportData={reportData?.collectionSummary} />

                        <Typography variant="h4" color="primary" sx={{ mt: 2 }}>
                            Details
                        </Typography>
                        <ReportTableList reportData={reportData?.collectionDetails} />
                    </>
                ) : (
                    <Stack spacing={3} alignItems="center" sx={{ marginY: 5 }}>
                        <CircularProgress />
                        <Typography variant="h4" color="secondary">
                            Generating Report, Please Wait...
                        </Typography>
                    </Stack>
                )}
            </>
        </MainCard>
    );
};

export default CustomerProfileCollectionReport;
