import { useSelector } from 'react-redux';

// material-ui
import { Typography } from '@mui/material';

// project imports
import NavGroup from './NavGroup';
import adminMenuItems from 'menu-items/adminMenuItems';
import customerMenuItems from 'menu-items/customerMenuItems';
import recoveryMenuItems from 'menu-items/recoveryMenuItem';
import accountMenuItems from 'menu-items/accountMenuItem';
import routeMenuItems from 'menu-items/routeMenuItem';
import recoveryExecutiveMenuItem from 'menu-items/recoveryExecutiveMenuItem';
import routeExecutiveMenuItem from 'menu-items/routeExecutiveMenuItem';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
    const { roleId } = useSelector((state) => state.userReducer);

    let menuItemsTobAdded = [];
    if (roleId == 2) {
        menuItemsTobAdded = customerMenuItems;
    } else if (roleId == 3) {
        menuItemsTobAdded = recoveryMenuItems;
    } else if (roleId == 4) {
        menuItemsTobAdded = recoveryExecutiveMenuItem;
    } else if (roleId == 5) {
        menuItemsTobAdded = routeMenuItems;
    } else if (roleId == 8) {
        menuItemsTobAdded = routeExecutiveMenuItem;
    } else if (roleId == 6) {
        menuItemsTobAdded = accountMenuItems;
    } else menuItemsTobAdded = adminMenuItems;

    const navItems = menuItemsTobAdded.map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return <>{navItems}</>;
};

export default MenuList;
