import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

//material ui imports
import {
    Grid,
    Table,
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Card,
    Paper,
    CardHeader,
    Typography,
    CardContent,
    Button,
    IconButton,
    Dialog,
    DialogContent,
    DialogTitle,
    DialogContentText,
    DialogActions,
    Divider
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import { useTheme } from '@mui/material/styles';

//propject imports
import DriverForm from './EmployeeForm';
import config from 'config';

//third party imports
import axios from 'axios';
import EmployeeForm from './EmployeeForm';
const Employee = () => {
    const theme = useTheme();
    const { userId } = useSelector((state) => state.userReducer);
    const [employee, setEmployee] = useState();
    const [selectedEmployee, setSelectedEmployee] = useState();
    const [openForm, setOpenForm] = useState(false);
    const [openDeleteDlg, setOpenDeleteDlg] = useState(false);

    useEffect(() => {
        getEmployee();
    }, []);

    const getEmployee = () => {
        axios
            .get(config.serverURL + '/api/Users/staff-list')
            .then((response) => {
                if (response.status === 200) {
                    console.log(response.data);
                    setEmployee(response.data);
                } else {
                    console.log('Error');
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    return (
        <>
            <Card>
                <CardHeader
                    title="Employee's"
                    sx={{ paddingBottom: 0 }}
                    action={
                        <Button
                            variant="rounded"
                            color="secondary"
                            size="small"
                            startIcon={<AddIcon />}
                            sx={{
                                transition: 'all .2s ease-in-out',
                                background: theme.palette.secondary.light,
                                color: theme.palette.secondary.dark,
                                '&[aria-controls="menu-list-grow"],&:hover': {
                                    background: theme.palette.secondary.dark,
                                    color: theme.palette.secondary.light
                                },
                                paddingX: 2
                            }}
                            onClick={() => {
                                setSelectedEmployee({
                                    id: null,
                                    name: '',
                                    nameLocal: '',
                                    mobileNumber: '',
                                    alternateMobileNumber: '',
                                    liscenceNumber: '',
                                    photoURL: '',
                                    address: '',
                                    printer: 'MAC',
                                    weighingScale: 'MAC'
                                });
                                setOpenForm(true);
                            }}
                        >
                            Add
                        </Button>
                    }
                ></CardHeader>
                <Divider sx={{ marginY: 3 }} />
                <CardContent>
                    <TableContainer>
                        <Table>
                            <TableHead sx={{ backgroundColor: theme.palette.grey[200] }}>
                                <TableRow>
                                    <TableCell>Sr</TableCell>
                                    <TableCell align="left">Name</TableCell>
                                    <TableCell align="left">Role</TableCell>
                                    <TableCell align="left">Mobile No.</TableCell>
                                    <TableCell align="left">Email Address</TableCell>
                                    <TableCell align="left">Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {employee?.map((row, i) => (
                                    <TableRow key={row.id}>
                                        <TableCell component="th" scope="row">
                                            {i + 1}
                                        </TableCell>
                                        <TableCell align="left">{row.displayName}</TableCell>
                                        <TableCell align="left">{row.rolename}</TableCell>
                                        <TableCell align="left">{row.mobileNumber}</TableCell>
                                        <TableCell align="left">{row.emailAddress}</TableCell>
                                        <TableCell align="left">
                                            <IconButton
                                                variant="outlined"
                                                color="primary"
                                                size="small"
                                                onClick={() => {
                                                    setSelectedEmployee(row.id);
                                                    setOpenForm(true);
                                                }}
                                            >
                                                <EditIcon fontSize="inherit" />
                                            </IconButton>
                                            <IconButton
                                                color="error"
                                                size="small"
                                                onClick={() => {
                                                    setSelectedEmployee(row);
                                                    setOpenDeleteDlg(true);
                                                }}
                                            >
                                                <DeleteIcon fontSize="inherit" />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CardContent>
            </Card>
            <Dialog
                open={openForm}
                onClose={() => {
                    setOpenForm(false);
                }}
                fullWidth
            >
                <DialogContent>
                    <EmployeeForm
                        onSave={() => {
                            setOpenForm(false);
                            getEmployee();
                        }}
                        onCancel={() => {
                            setOpenForm(false);
                        }}
                        employee={selectedEmployee}
                    />
                </DialogContent>
            </Dialog>
            <Dialog
                open={openDeleteDlg}
                onClose={() => {
                    setOpenDeleteDlg(false);
                }}
                fullWidth
            >
                <DialogTitle>
                    <Typography variant="h4" color={theme.palette.secondary.dark}>
                        Delete Employee
                    </Typography>
                    <Divider sx={{ marginTop: 2 }}></Divider>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete Employee : <b>{selectedEmployee?.fullName}</b>?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            axios
                                .delete(config.serverURL + `/api/Users?id=${selectedEmployee.id}&deletedBy=${userId}`)
                                .then((response) => {
                                    setOpenDeleteDlg(false);
                                    getEmployee();
                                })
                                .catch((error) => {
                                    console.log(error);
                                    setOpenDeleteDlg(false);
                                });
                        }}
                        variant="contained"
                    >
                        Yes
                    </Button>
                    <Button
                        onClick={() => {
                            setOpenDeleteDlg(false);
                        }}
                        variant="outlined"
                    >
                        No
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default Employee;
