import React from 'react';
import LetterHeadInvoice from 'ui-component/LetterHeadInvoice';
import { useTheme } from '@mui/material/styles';
import { Divider, Stack, Typography } from '@mui/material';
import ReportTableList from './ReportTableList';
import ReportTableTotal from './ReportTableTotal';

const CustomerProfileCollectionReportPrint = React.forwardRef((props, ref) => {
    const { tableTotalData, tableListData, customerDetails, fromDate, toDate } = props;

    const theme = useTheme();

    const getPageMargins = () => {
        return `@page { margin: ${'40px'} ${'40px'} ${'40px'} ${'40px'} !important; }`;
    };
    const pageStyle = '@page {size: A4 portrait;}';
    return (
        <div ref={ref}>
            <style>{getPageMargins()}</style>
            <div style={{ position: 'fixed', top: '0', width: '100%' }}>
                <LetterHeadInvoice />
            </div>
            <table style={{ width: '100%' }}>
                <thead>
                    <tr>
                        <td>
                            <div style={{ height: '150px' }}></div>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <div>
                                <Typography variant="h4" align="center" sx={{ mb: 1 }}>
                                    Waste Collection Report
                                </Typography>
                                <Typography variant="h5">{customerDetails?.hospitalName}</Typography>
                                <Stack direction="row" alignItems="center" spacing={1} sx={{ marginTop: 0.5 }}>
                                    <Typography sx={{ color: theme.palette.grey[500] }} fontWeight="medium">
                                        {`${customerDetails?.salutationDisplay}. `} {customerDetails?.doctorFirstName}{' '}
                                        {customerDetails?.doctorMiddleName} {customerDetails?.doctorLastName}
                                    </Typography>
                                    <Typography sx={{ color: theme.palette.grey[500] }} fontWeight="medium">
                                        |
                                    </Typography>
                                    <Typography sx={{ color: theme.palette.grey[500] }} fontWeight="medium">
                                        {customerDetails?.uin}
                                    </Typography>
                                    <Typography sx={{ color: theme.palette.grey[500] }} fontWeight="medium">
                                        |
                                    </Typography>
                                    <Typography sx={{ color: theme.palette.grey[500] }} fontWeight="medium">
                                        Date: {new Date(fromDate).toLocaleDateString('hi-IN')} to{' '}
                                        {new Date(toDate).toLocaleDateString('hi-IN')}
                                    </Typography>
                                </Stack>
                                <Divider sx={{ marginTop: 1 }} />
                                <ReportTableTotal reportData={tableTotalData} />
                                <ReportTableList reportData={tableListData} />
                                <Divider sx={{ borderBottomWidth: 1, mt: 5, mb: 2, bgcolor: '#000000' }} />
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
});

export default CustomerProfileCollectionReportPrint;
