import { useRoutes } from 'react-router-dom';
import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

// routes
import config from 'config';
//redux import
import { useSelector } from 'react-redux';
import MinimalLayout from 'layout/MinimalLayout';
import MainLayout from 'layout/MainLayout';

import Loadable from 'ui-component/Loadable';
import CustomerProfileCollectionReport from 'views/customer-profile/Reports/CustomerProfileCollectionReport';
import MachineMaster from 'views/plant/machines/machine-master';
import MachineDetails from 'views/plant/machines/machine-master/machine-details';
import MachineMaintenanceLogs from 'views/plant/machines/maintenance-logs';
import Employee from 'views/admin-pages/employee';
import Data from 'views/data';

const AuthLogin3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Login3')));
const AuthRegister3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Register3')));
const Login = Loadable(lazy(() => import('views/login')));

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

const CustomerList = Loadable(lazy(() => import('views/customer-list')));
const SamplePage = Loadable(lazy(() => import('views/sample-page')));
const CustomerProfile = Loadable(lazy(() => import('views/customer-profile')));
const CustomerRegistrationForm = Loadable(lazy(() => import('views/customer-rgistration-form')));
const CustomerNewAccountForm = Loadable(lazy(() => import('views/customer-new-account')));
const CollectionRoutes = Loadable(lazy(() => import('views/collection-routes')));
const RouteSchedule = Loadable(lazy(() => import('views/route-schedule')));
const TodaysRoutes = Loadable(lazy(() => import('views/collection-routes/todays-routes')));
const Rescanning = Loadable(lazy(() => import('views/collection-routes/rescanning')));
const Tasks = Loadable(lazy(() => import('views/tasks')));
const Reports = Loadable(lazy(() => import('views/reports')));
const TaskDetails = Loadable(lazy(() => import('views/tasks/task-details')));

//Settings page routing
const AreaRates = Loadable(lazy(() => import('views/admin-pages/area-rates')));
const Drivers = Loadable(lazy(() => import('views/admin-pages/drivers')));
const Vehicles = Loadable(lazy(() => import('views/admin-pages/vehicles')));
const RouteScheduleTemplate = Loadable(lazy(() => import('views/admin-pages/route-schedule-template')));

//Bills and Receipts
const BillReceipts = Loadable(lazy(() => import('views/prints/index')));

//Acounts
const Accounts = Loadable(lazy(() => import('views/accounts')));

//Certificate List
const CertificateList = Loadable(lazy(() => import('views/certificates-list/index')));

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    const { userId, userName, roleId, displayRole } = useSelector((state) => state.userReducer);

    const AuthenticationRoutes = {
        path: '/',
        element: <MinimalLayout />,
        children: [
            {
                path: '/login',
                element: <Login />
            },
            {
                path: '/pages/register/register3',
                element: <AuthRegister3 />
            }
        ]
    };

    const MainRoutes = {
        path: '/',
        element: <MainLayout />,
        children: [
            {
                path: '/',
                element:
                    userId > 0 && (roleId == 1 || roleId == 3 || roleId == 4 || roleId == 5 || roleId == 6 || roleId == 8) ? (
                        <CustomerList />
                    ) : (
                        <Navigate to="/login" />
                    )
            },
            {
                path: '/dashboard',
                element: <DashboardDefault />
            },
            {
                path: '/customer-list',
                element:
                    userId > 0 && (roleId == 1 || roleId == 3 || roleId == 4 || roleId == 5 || roleId == 6 || roleId == 8) ? (
                        <CustomerList />
                    ) : (
                        <Navigate to="/login" />
                    )
            },
            {
                path: '/customer-new-registration',
                element: userId > 0 ? <CustomerRegistrationForm /> : <Navigate to="/login" />
            },
            {
                path: '/customer-new-account',
                element: userId > 0 ? <CustomerNewAccountForm /> : <Navigate to="/login" />
            },
            {
                path: '/customer/customer-profile',
                element: userId > 0 && roleId == 2 ? <CustomerProfile /> : <Navigate to="/login" />
            },
            {
                path: '/customer/collection-reports',
                element: userId > 0 && roleId == 2 ? <CustomerProfileCollectionReport /> : <Navigate to="/login" />
            },
            {
                path: '/area-rates',
                element:
                    userId > 0 && (roleId == 1 || roleId == 3 || roleId == 4 || roleId == 5 || roleId == 6 || roleId == 8) ? (
                        <AreaRates />
                    ) : (
                        <Navigate to="/login" />
                    )
            },
            {
                path: '/routes',
                element:
                    userId > 0 && (roleId == 1 || roleId == 3 || roleId == 4 || roleId == 5 || roleId == 6 || roleId == 8) ? (
                        <CollectionRoutes />
                    ) : (
                        <Navigate to="/login" />
                    )
            },
            {
                path: '/route-schedule',
                element:
                    userId > 0 && (roleId == 1 || roleId == 3 || roleId == 4 || roleId == 5 || roleId == 6 || roleId == 8) ? (
                        <RouteSchedule />
                    ) : (
                        <Navigate to="/login" />
                    )
            },
            {
                path: '/todays-routes',
                element:
                    userId > 0 && (roleId == 1 || roleId == 3 || roleId == 4 || roleId == 5 || roleId == 6 || roleId == 8) ? (
                        <TodaysRoutes />
                    ) : (
                        <Navigate to="/login" />
                    )
            },
            {
                path: '/rescanning',
                element:
                    userId > 0 && (roleId == 1 || roleId == 3 || roleId == 4 || roleId == 5 || roleId == 6 || roleId == 8) ? (
                        <Rescanning />
                    ) : (
                        <Navigate to="/login" />
                    )
            },
            {
                path: '/drivers',
                element:
                    userId > 0 && (roleId == 1 || roleId == 3 || roleId == 4 || roleId == 5 || roleId == 6 || roleId == 8) ? (
                        <Drivers />
                    ) : (
                        <Navigate to="/login" />
                    )
            },
            {
                path: '/users',
                element:
                    userId > 0 && (roleId == 1 || roleId == 3 || roleId == 4 || roleId == 5 || roleId == 6 || roleId == 8) ? (
                        <Employee />
                    ) : (
                        <Navigate to="/login" />
                    )
            },
            {
                path: '/vehicles',
                element:
                    userId > 0 && (roleId == 1 || roleId == 3 || roleId == 4 || roleId == 5 || roleId == 6 || roleId == 8) ? (
                        <Vehicles />
                    ) : (
                        <Navigate to="/login" />
                    )
            },
            {
                path: '/route-schedule-templates',
                element:
                    userId > 0 && (roleId == 1 || roleId == 3 || roleId == 4 || roleId == 5 || roleId == 6 || roleId == 8) ? (
                        <RouteScheduleTemplate />
                    ) : (
                        <Navigate to="/login" />
                    )
            },
            {
                path: '/reports',
                element:
                    userId > 0 && (roleId == 1 || roleId == 3 || roleId == 4 || roleId == 5 || roleId == 6 || roleId == 8) ? (
                        <Reports />
                    ) : (
                        <Navigate to="/login" />
                    )
            },
            {
                path: '/tasks',
                element:
                    userId > 0 && (roleId == 1 || roleId == 3 || roleId == 4 || roleId == 5 || roleId == 6 || roleId == 8) ? (
                        <Tasks />
                    ) : (
                        <Navigate to="/login" />
                    )
            },
            {
                path: '/task-details',
                element:
                    userId > 0 && (roleId == 1 || roleId == 3 || roleId == 4 || roleId == 5 || roleId == 6 || roleId == 8) ? (
                        <TaskDetails />
                    ) : (
                        <Navigate to="/login" />
                    )
            },
            {
                path: '/bill',
                element:
                    userId > 0 && (roleId == 1 || roleId == 3 || roleId == 4 || roleId == 5 || roleId == 6 || roleId == 8) ? (
                        <BillReceipts />
                    ) : (
                        <Navigate to="/login" />
                    )
            },
            {
                path: '/plant/machines',
                element:
                    userId > 0 && (roleId == 1 || roleId == 3 || roleId == 4 || roleId == 5 || roleId == 6 || roleId == 8) ? (
                        <MachineMaster />
                    ) : (
                        <Navigate to="/login" />
                    )
            },
            {
                path: '/plant/machine-details',
                element:
                    userId > 0 && (roleId == 1 || roleId == 3 || roleId == 4 || roleId == 5 || roleId == 6 || roleId == 8) ? (
                        <MachineDetails />
                    ) : (
                        <Navigate to="/login" />
                    )
            },
            {
                path: '/plant/machine-maintenance-logs',
                element:
                    userId > 0 && (roleId == 1 || roleId == 3 || roleId == 4 || roleId == 5 || roleId == 6 || roleId == 8) ? (
                        <MachineMaintenanceLogs />
                    ) : (
                        <Navigate to="/login" />
                    )
            },
            {
                path: '/accounts',
                element:
                    userId > 0 && (roleId == 1 || roleId == 3 || roleId == 4 || roleId == 5 || roleId == 6 || roleId == 8) ? (
                        <Accounts />
                    ) : (
                        <Navigate to="/login" />
                    )
            },
            {
                path: '/certificate-list',
                element:
                    userId > 0 && (roleId == 1 || roleId == 3 || roleId == 4 || roleId == 5 || roleId == 6 || roleId == 8) ? (
                        <CertificateList />
                    ) : (
                        <Navigate to="/login" />
                    )
            },
            {
                path: '/data',
                element:
                    userId > 0 && (roleId == 1 || roleId == 3 || roleId == 4 || roleId == 5 || roleId == 6 || roleId == 8) ? (
                        <Data />
                    ) : (
                        <Navigate to="/login" />
                    )
            }
        ]
    };

    return useRoutes([MainRoutes, AuthenticationRoutes], config.basename);
}
