// assets
import {
    IconDashboard,
    IconBrandChrome,
    IconHelp,
    IconUsers,
    IconRoute,
    IconLayoutKanban,
    IconReport,
    IconSettings,
    IconSubtask,
    IconBuildingFactory,
    IconCurrencyRupee,
    IconCertificate
} from '@tabler/icons';

// constant
const icons = {
    IconDashboard,
    IconBrandChrome,
    IconHelp,
    IconUsers,
    IconRoute,
    IconLayoutKanban,
    IconReport,
    IconSettings,
    IconSubtask,
    IconBuildingFactory,
    IconCurrencyRupee,
    IconCertificate
};

// ==============================|| ADMIN MENU ITEMS ||============================== //

const accountMenuItems = [
    // {
    //     id: 'dashboard',
    //     type: 'group',
    //     children: [
    //         {
    //             id: 'default',
    //             title: 'Dashboard',
    //             type: 'item',
    //             url: '/dashboard',
    //             icon: icons.IconDashboard,
    //             breadcrumbs: false
    //         }
    //     ]
    // },
    {
        id: 'main-pages',
        type: 'group',
        children: [
            {
                id: 'customers',
                title: 'Customers',
                type: 'collapse',
                icon: icons.IconUsers,
                breadcrumbs: false,
                children: [
                    {
                        id: 'customer-list',
                        title: 'List',
                        type: 'item',
                        url: '/customer-list',
                        breadcrumbs: false
                    }
                    // {
                    //     id: 'new-registration',
                    //     title: 'Register New Customer',
                    //     type: 'item',
                    //     url: '/customer-new-account',
                    //     breadcrumbs: false
                    // }
                ]
            },
            // {
            //     id: 'routes',
            //     title: 'Routes & Collection',
            //     type: 'collapse',
            //     icon: icons.IconRoute,
            //     breadcrumbs: false,
            //     children: [
            //         {
            //             id: 'routes',
            //             title: 'Manage Routes',
            //             type: 'item',
            //             url: '/routes',
            //             breadcrumbs: false
            //         },
            //         {
            //             id: 'route-schedule',
            //             title: 'Route Schedule',
            //             type: 'item',
            //             url: '/route-schedule',
            //             breadcrumbs: false
            //         },
            //         {
            //             id: 'todays-routes',
            //             title: 'Todays Routes',
            //             type: 'item',
            //             url: '/todays-routes',
            //             breadcrumbs: false
            //         },
            //         {
            //             id: 'rescanning',
            //             title: 'Rescanning',
            //             type: 'item',
            //             url: '/rescanning',
            //             breadcrumbs: false
            //         }
            //     ]
            // },
            {
                id: 'tasks',
                title: 'Tasks',
                type: 'item',
                url: '/tasks',
                icon: icons.IconSubtask,
                breadcrumbs: false
            },
            {
                id: 'certificate-list',
                title: 'Certificates',
                type: 'item',
                url: '/certificate-list',
                icon: icons.IconCertificate,
                breadcrumbs: false
            }
        ]
    },
    {
        id: 'accounts-pages',
        type: 'group',
        children: [
            {
                id: 'other',
                title: 'Accounts',
                type: 'item',
                url: '/accounts',
                icon: icons.IconCurrencyRupee,
                breadcrumbs: false
            }
        ]
    },
    {
        id: 'setting-pages',
        type: 'group',
        children: [
            {
                id: 'admin',
                title: 'Settings',
                type: 'collapse',
                icon: icons.IconSettings,
                breadcrumbs: false,
                children: [
                    {
                        id: 'rate-chart',
                        title: 'Areas & Rates',
                        type: 'item',
                        url: '/area-rates',
                        breadcrumbs: false
                    },
                    // {
                    //     id: 'employee',
                    //     title: 'Employees',
                    //     type: 'item',
                    //     url: '/employee',
                    //     breadcrumbs: false
                    // },
                    {
                        id: 'drivers',
                        title: 'Drivers',
                        type: 'item',
                        url: '/drivers',
                        breadcrumbs: false
                    },
                    {
                        id: 'vehicles',
                        title: 'Vehicles',
                        type: 'item',
                        url: '/vehicles',
                        breadcrumbs: false
                    },
                    {
                        id: 'routeScheduleTemplates',
                        title: 'Route Schedule Templates',
                        type: 'item',
                        url: '/route-schedule-templates',
                        breadcrumbs: false
                    }
                ]
            },
            {
                id: 'reports',
                title: 'Reports',
                type: 'item',
                url: '/reports',
                icon: icons.IconReport,
                breadcrumbs: false
            },
            {
                id: 'data',
                title: 'Data',
                type: 'item',
                url: '/data',
                icon: icons.IconDatabase,
                breadcrumbs: false
            }
        ]
    }
];

export default accountMenuItems;
