import { useState, useEffect } from 'react';

import { Button, Card, CardContent, CardHeader, Divider, Link, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const Data = () => {
    const theme = useTheme();
    return (
        <Card>
            <CardHeader title="Company Data" sx={{ paddingBottom: 0 }}></CardHeader>
            <Divider sx={{ marginTop: 3 }} />
            <CardContent>
                <Typography variant="h2" color="primary">
                    Important Links
                </Typography>
                <Typography variant="h4" sx={{ marginTop: 2, marginBottom: 1 }}>
                    Websites
                </Typography>
                <Stack direction="row" spacing={3}>
                    <Link href="https://suryactfbmw.in" target="_blank" sx={{ alignContent: 'center', display: 'flex' }}>
                        Surya Website
                    </Link>
                </Stack>
                <Typography variant="h4" sx={{ marginTop: 2, marginBottom: 1 }}>
                    OEMS
                </Typography>
                <Stack direction="row" spacing={3}>
                    <Link href="https://sustainability.logicladder.com/" target="_blank" sx={{ alignContent: 'center', display: 'flex' }}>
                        Logic Ladder
                    </Link>
                    <Link href="http://rtdms.cpcb.gov.in/industry-login" target="_blank" sx={{ alignContent: 'center', display: 'flex' }}>
                        CPCB Connectivity of OEMS
                    </Link>
                    <Link href="https://onlinecems.ecmpcb.in/" target="_blank" sx={{ alignContent: 'center', display: 'flex' }}>
                        MPCB of OEMS
                    </Link>
                </Stack>

                <Typography variant="h4" sx={{ marginTop: 2, marginBottom: 1 }}>
                    MPCB Portal
                </Typography>
                <Stack direction="row" spacing={3}>
                    <Link href="https://www.ecmpcb.in/" target="_blank" sx={{ alignContent: 'center', display: 'flex' }}>
                        MPCB Portal for Documents upload & updtaes from MPCB
                    </Link>
                </Stack>

                <Typography variant="h4" sx={{ marginTop: 2, marginBottom: 1 }}>
                    Naas
                </Typography>
                <Stack direction="row" spacing={3}>
                    <Link
                        href="https://www.google.com/url?q=https://device-local-8b59c3a5-1426-4bbb-8163-e4144a390729.remotewd.com:8543/&sa=D&source=editors&ust=1701498056695205&usg=AOvVaw0XmKK1g78vArWojX5-V_z2"
                        target="_blank"
                        sx={{ alignContent: 'center', display: 'flex' }}
                    >
                        For Staff working, IMP Documents, IMP Charts
                    </Link>
                </Stack>

                <Typography variant="h4" sx={{ marginTop: 2, marginBottom: 1 }}>
                    Emails
                </Typography>
                <Stack direction="row" spacing={3}>
                    <Link href="https://mail.google.com/" target="_blank" sx={{ alignContent: 'center', display: 'flex' }}>
                        Official Gmail Mail A/cs
                    </Link>
                </Stack>

                <Typography variant="h4" sx={{ marginTop: 2, marginBottom: 1 }}>
                    Single Window Clearance ( SWC )
                </Typography>
                <Stack direction="row" spacing={3}>
                    <Link href="https://services.midcindia.org/services/" target="_blank" sx={{ alignContent: 'center', display: 'flex' }}>
                        Single Window Clearance ( SWC )
                    </Link>
                </Stack>

                <Typography variant="h4" sx={{ marginTop: 2, marginBottom: 1 }}>
                    BMW Core Drive
                </Typography>
                <Stack direction="row" spacing={3}>
                    <Link href="https://drive.google.com/" target="_blank" sx={{ alignContent: 'center', display: 'flex' }}>
                        BMW Core Drive for IMP Documents & Earlier Company Date
                    </Link>
                </Stack>

                <Typography variant="h4" sx={{ marginTop: 2, marginBottom: 1 }}>
                    GPS Tracking
                </Typography>
                <Stack direction="row" spacing={3}>
                    <Link href="https://app.sparkvts.in/" target="_blank" sx={{ alignContent: 'center', display: 'flex' }}>
                        Vehicle Tracking
                    </Link>
                </Stack>
            </CardContent>
        </Card>
    );
};

export default Data;
