import { Box, Divider, Grid, Typography } from '@mui/material';
import React from 'react';
import Logo from '../assets/images/logo.png';
import MrkLogo from '../assets/images/logo-mrk.png';

const LetterHeadInvoice = () => {
    return (
        <Box>
            <Box
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: '10px'
                }}
            >
                <Box style={{ display: 'flex' }}>
                    <Box style={{ marginRight: '10px' }}>
                        <img src={Logo} alt="company name" width="80px" />
                    </Box>
                </Box>

                <Box textAlign="center">
                    <Typography variant="h3" color="primary.dark">
                        SURYA CENTER TREATMENT FACILITY PVT. LTD.
                    </Typography>
                    <Typography sx={{ mt: 1 }} variant="h4">
                        BIO MEDICAL WASTE DISPOSAL PLANT
                    </Typography>
                </Box>
                <Box textAlign="right">
                    <img src={MrkLogo} alt="company name" width="80px" />
                </Box>
            </Box>
            <Box>
                <Typography variant="h5" textAlign="center">
                    C/o Kore Lawns, Sahayog Nager Near Sphurti Chowk, Vishrambag, Sangli-416415
                </Typography>
                <Typography variant="h5" textAlign="center">
                    Mobile: 8411811005/9823184999, Landline: 0233-3594187, GSTIN - 27ABJCS9911R1ZC
                </Typography>
            </Box>
            <Divider sx={{ borderBottomWidth: 1, mt: 1, mb: 2, bgcolor: '#000000' }} />
        </Box>
    );
};

export default LetterHeadInvoice;
